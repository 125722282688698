@import "./main.css";
@import "react-big-calendar/lib/css/react-big-calendar.css";
.ant-btn {
  @apply flex items-center justify-center;
}

.overflow-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container {
  @apply overflow-x-auto;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popover-content-w-full {
  .ant-popover-inner-content {
    @apply w-full p-0;
  }
}

// .ant-popover-inner-content {
//   @apply w-full p-0;
// }

.select-project-modal > .ant-modal-content {
  .ant-modal-header {
    padding: 8px 12px;
  }
  .ant-modal-body {
    padding: 8px 12px;
  }
  .ant-modal-footer {
    padding: 8px 12px;
  }
}
.ant-popover-buttons {
  display: flex;
}
.processed-data-table {
	.ant-table-wrapper {
		.ant-spin-nested-loading > .ant-spin-container > .ant-table {
			overflow: scroll;
			.ant-table-container {
				width: max-content;
			}
		}
	}
}

.rbc-event {
  background-color: inherit !important;
  color: inherit !important;
  border: unset !important;
 
}